<footer *ngIf="footerInfo as footer" class="container">
  <div class="row justify-content-center footer">
    <div *ngFor="let menu of footer.footerMenu" class="col-md-3">
      <h3 class="headline">{{ menu.title }}</h3>
      <ul>
        <li *ngFor="let data of menu.links">
          <a *ngIf="data.link" class="menu font-weight-normal" rel="noopener" target="_blank" [href]="data.link" [title]="data.title">
            <i *ngIf="data.icon" [class]="data.icon"></i> {{data.title}}
          </a>
          <span *ngIf="!data.link && data.title" class="menu font-weight-normal">
            <i *ngIf="data.icon" [class]="data.icon"></i> {{data.title}}
          </span>
        </li>
      </ul>
    </div>
  </div>


  <div class="row">
    <ul class="social-media col-md-4 offset-md-4">
      <li *ngFor="let item of footer.footerSocialNetworks">
        <a [href]="item.link" target="_blank" rel="noopener">
          <img [src]="'/assets/img/'+item.icon+'.png'" alt="">
        </a>
      </li>
      <ng-container *ngIf="footer.footerCopyright">
        <p class="text-center">{{footer.footerCopyright[0].title}}</p>
      </ng-container>
    </ul>
    <div class="partner col-md-4 text-right">
      <p>Official Partner:</p>
      <img src="/assets/img/ispologov2.png" alt="ISPO" width="160">
    </div>
  </div>
</footer>
