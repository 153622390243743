<ng-container *ngIf="(categories$ | async) as elements; else loadingState">
  <header class="w-100"><img class="w-100" height="400px" [src]="getBackgroundImg(elements.uid)" alt="" /></header>
  <div class="container">
    <div class="content-container-fat main-container pb-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Manufacturers directory</li>
        </ol>
      </nav>
      <div class="header-content">
        <h1 class="headline">
          <b>FOURSOURCE</b> <br />
          <span>the world's largest sourcing network</span>
        </h1>
        <h2 class="menu">
          In our manufacturer network you find {{ elements.totalManufacturers }} manufacturers of
          {{ elements.totalCountries }} countries in {{ elements.totalCities }} cities all over the world. Select one of
          the {{ elements.totalEntries }} {{ env.subdomain === 'fabrics' ? 'fabric-types' : env.subdomain }} below to
          find the perfect manufacturer for your planings.
        </h2>
        <p class="menu">FOURSOURCE is the world’s largest sourcing network in the apparel industry.</p>
        <a href="https://foursource.com/signup" target="_blank" rel="noopener" class="btn btn-success">Join for free</a>
      </div>
      <div class="body-content">
        <h3 class="menu">Manufacturer {{ env.subdomain }}:</h3>
        <div class="row">
          <mat-card *ngFor="let category of elements.list" class="col-md-3 col-sm-6 align-items-start">
            <a class="card-style" [routerLink]="'/manufacturers/' + category.uid">
              <mat-card-content>
                <p>{{ category.name }}</p>
              </mat-card-content>
            </a>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingState>
  <div class="container">
    <div class="content-container-fat main-container">
      <img class="img-fluid" src="/assets/img/indexpagesplaceholder.svg" alt="" />
    </div>
  </div>
</ng-template>
