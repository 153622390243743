import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var SeoService = /** @class */ (function () {
    function SeoService(_dom) {
        this._dom = _dom;
    }
    SeoService.prototype.createCanonicalURL = function () {
        var canonical = this._dom.querySelector('link[rel="canonical"]');
        canonical.setAttribute('href', environment.app_url + this._dom.location.pathname);
    };
    SeoService.ngInjectableDef = i0.defineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.inject(i1.DOCUMENT)); }, token: SeoService, providedIn: "root" });
    return SeoService;
}());
export { SeoService };
