import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CONSTANTS, makeSymbolPath, UrlResolver } from '@app/common';
import { SeoService } from '@app/common/services/seo.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DomainService } from '../domain.service';
import { DomainCountryDetailsModel, FooterInfoModel } from '../domain.typings';

@Component({
  templateUrl: './domain-detail.component.html',
  styleUrls: ['./domain-detail.component.scss'],
})
export class DomainDetailComponent implements OnInit {
  public categoryCountryDetails$: Observable<DomainCountryDetailsModel>;
  public footerInfoDetails$: Observable<FooterInfoModel>;
  public categoryImagePath: string;
  public countryImagePath: string;
  public isCity = false;
  public environment = environment;

  constructor(
    private _url: UrlResolver,
    private _router: Router,
    private _localService: DomainService,
    private _activeRoute: ActivatedRoute,
    private _title: Title,
    private _meta: Meta,
    private _seoService: SeoService,
    @Inject(DOCUMENT) private _dom: Document
  ) {
    this._seoService.createCanonicalURL();
  }

  public ngOnInit() {
    this._setEntityPaths();

    this._activeRoute.params.forEach((params: Params) => {
      const url_params = {
        category_uid: params.category,
        country_uid: params.country ? params.country : '',
        city_uid: params.city ? params.city : '',
      };

      this.isCity = params.city ? true : false;

      this.categoryCountryDetails$ = this._localService.getCategoryCountryDetails(url_params).pipe(
        tap(res => {
          if (res && res.city) {
            this._title.setTitle(
              res.name + ' Manufacturers in ' + res.city.name + '/' + res.country.name + ' | FOURSOURCE'
            );
          } else {
            this._title.setTitle(res.name + ' Manufacturers in ' + res.country.name + ' | FOURSOURCE');
          }
          const el = this._dom.createElement('p');
          const el_2 = this._dom.createElement('p');
          const html = '&#10003;';
          const html_2 = '&#9654;';
          el.innerHTML = html;
          el_2.innerHTML = html_2;
          this._meta.updateTag({
            name: 'description',
            content:
              res.name +
              ' Manufacturers in ' +
              (res.city ? res.city.name + '/' + res.country.name : res.country.name) +
              ' ' +
              el.innerHTML +
              ' Industry specific search ' +
              el.innerHTML +
              ' Access to a global database ' +
              el.innerHTML +
              ' Digital supplier showrooms ' +
              el_2.innerHTML +
              ' Sign up FREE',
          });
        })
      );
      this.footerInfoDetails$ = this._localService.getFooterInfo(url_params);
    });
  }

  public getImgPath(id: number) {
    return this.categoryImagePath + id + '.jpg';
  }

  public getBackgroundImg(iso_code: string) {
    return this.countryImagePath + iso_code + '.jpg';
  }

  private _setEntityPaths() {
    const staticDomain = this._url.get(makeSymbolPath([CONSTANTS.ENDPOINTS, CONSTANTS.STATICIMAGESDOMAIN]));

    this.categoryImagePath = `${staticDomain}${environment.subdomain}/md/`;
    this.countryImagePath = `${staticDomain}countries/md/`;
  }
}
