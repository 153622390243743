import { Injectable, Inject } from '@angular/core';

import { Options, OptionsToken } from './types';
import { isObject, mapKeys, set, startsWith } from 'lodash';

/**
 * Class to centralized configurations values.
 *
 * @export
 * Configurator
 */
@Injectable()
export class Configurator {
  private repository: Options = {} as Options;

  constructor(@Inject(OptionsToken) options?: Options | undefined) {
    this.options = options || {};
  }

  public setOption(name: string, value: any): void {
    this.repository[name] = value;
  }

  /**
   * Get a configuration value from the collection.
   *
   */
  public getOption<O>(name: string, defaults: any = null): O {
    return this.hasOption(name) ? this.repository[name] : defaults;
  }

  public getOptionTree<T>(rootKey: string, fromRoot: boolean = true): T {
    const tree = {};

    mapKeys(this.options, (value: any, key: string) => {
      if (startsWith(key, rootKey)) {
        set(tree, key, value);
      }

      return key;
    });

    return fromRoot ? tree : tree[rootKey];
  }

  get options(): Options {
    return this.repository;
  }

  set options(opt: Options) {
    this.flat(opt);
  }

  /**
   * Verify if option name exists on the collection.
   *
   */
  public hasOption(name: string): boolean {
    return this.repository.hasOwnProperty(name);
  }

  /**
   * Converts a tree object keys in flat
   * key string in one level.
   *
   * {
   *  name: '',
   *  profile: {
   *    email: ''
   *  }
   * }
   *
   * to: {'name': '', 'profile.email': ''}
   *
   */
  private flat(config: any, key: string = ''): void {
    const path: string = +(key === '') ? key : key + '.';

    Object.keys(config).forEach((keyId: string) => {
      if (isObject(config[keyId])) {
        this.flat(config[keyId], path + keyId);
      } else {
        this.setOption(`${path + keyId}`, config[keyId]);
      }
    });
  }
}
