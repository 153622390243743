<app-navbar></app-navbar>

<div *ngIf="loading" class="container">
  <div class="content-container-fat">
    <img class="img-fluid" src="/assets/img/indexpagesplaceholder.svg">
  </div>
</div>

<router-outlet></router-outlet>
<app-footer></app-footer>

