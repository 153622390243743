import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '@app/common/constants';
import { UrlResolver } from '@app/common/url';
import { first, map } from 'rxjs/operators';
import { AnalyticsService } from './common/services/analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./common/url";
import * as i3 from "./common/services/analytics.service";
var AppService = /** @class */ (function () {
    function AppService(_http, _url, _analytics) {
        this._http = _http;
        this._url = _url;
        this._analytics = _analytics;
    }
    AppService.prototype.getAlive = function () {
        var _this = this;
        var endpoint = this._url.get(CONSTANTS.ALIVE).replace('/fourall', '');
        return this._http.get(endpoint, { observe: 'response' }).pipe(first(), map(function (response) {
            _this._analytics.isChina = response.headers.get('ischina');
            return response.headers.get('ischina') || false;
        }));
    };
    AppService.ngInjectableDef = i0.defineInjectable({ factory: function AppService_Factory() { return new AppService(i0.inject(i1.HttpClient), i0.inject(i2.UrlResolver), i0.inject(i3.AnalyticsService)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
