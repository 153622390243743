import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { AnalyticsService } from '@app/common/services/analytics.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public loading = true;
  private isChina: string;

  private _destroyer: Subject<void> = new Subject<void>();

  constructor(
    private _router: Router,
    private _detectionStrategy: ChangeDetectorRef,
    private _analyticsService: AnalyticsService,
    private _localService: AppService
  ) {}

  public ngOnInit() {
    this._localService.getAlive().subscribe((isChina) => {
      this.isChina = isChina;
    });

    this._router.events.pipe(takeUntil(this._destroyer)).subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  public ngOnDestroy(): void {
    this._destroyer.next();
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }

    if (event instanceof NavigationEnd) {
      if (!this.isChina) {
        this.analytics();
      }
      this.loading = false;
    }

    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.loading = false;
    }

    this._detectionStrategy.markForCheck();
    this._detectionStrategy.detectChanges();
  }

  private analytics() {
    this._analyticsService.setupAnalytics();
  }
}
