import { Injectable } from '@angular/core';

import { find, includes, head, last } from 'lodash';

@Injectable()
export class Collection<T> {
  private list: T[] = [];

  public set(collection: T[]): void {
    this.list = [].concat(collection);
  }

  public find(item: any): T {
    return find(this.list, item);
  }

  public exist(item: any): boolean {
    return includes(this.list, item);
  }

  public add(item: any): void {
    this.list.push(item);
  }

  public first(): T {
    return head(this.list);
  }

  public last(): T {
    return last(this.list);
  }

  public getByIndex(index: number): T {
    return this.list[index];
  }

  public all(): T[] {
    return this.list;
  }

  public isEmpty(): boolean {
    return this.list.length > 0 ? false : true;
  }

  public count(): number {
    return this.list.length;
  }
}
