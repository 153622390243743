import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material';
import { DomainDetailComponent } from './domain-detail/domain-detail.component';
import { CategoriesRoutingModule } from './domain-routing.module';
import { DomainViewComponent } from './domain-view/domain-view.component';
import { DomainComponent } from './domain.component';

@NgModule({
  declarations: [DomainComponent, DomainViewComponent, DomainDetailComponent],
  imports: [CommonModule, CategoriesRoutingModule, MatCardModule, HttpClientModule],
  providers: [HttpClient],
})
export class DomainModule {}
