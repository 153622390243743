export const enum ENUM_DOMAIN {
  BUYER = 'buyer',
  MANUFACTURER = 'manufacturer',
}

export const CONSTANTS = {
  /* tslint:disable */
  EMAILREGEX:
    "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+([.][a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+)+$",
  PHONEREGEX: '^[0-9+() /-]+$',
  ONLY_LETTERS_REGEX: '^[a-zA-Z]+$',
  ONLY_NUMBERS_REGEX: '^[0-9]+[,0-9]*$',
  PRICE_REGEX: '^[0-9]+[.,0-9]*$',
  ALPHANUMERIC_REGEX: '^[a-zA-Z0-9]+$',
  CITY_REGEX: '^[a-zA-Z]+.*$',
  GPS_REGEX: '^[+|-]?[0-9]+.?[0-9]*,[+|-]?[0-9]+.?[0-9]*$',
  URL_REGEX: '^[a-zA-Z0-9-_/:.]+[.][a-zA-Z0-9-_/.]+$',
  INVALID_CHARS_REGEX: '^[^?!+|:;.<>]+$',
  NAME_REGEX: '^[^?!+|:;/.<>0-9]+$',
  ANTI_SPACE_REGEX: '^(.|\n)*([a-zA-Z0-9]+)(.|\n)*$',
  /* tslint:enable */
  USER: 'user',
  BASIC: {
    USERNAME: 'BASIC.USERNAME',
    PASSWORD: 'BASIC.PASSWORD',
    DEFAULT: 'BASIC.DEFAULT',
  },
  TYPES: {
    COMPANY: 'company',
    BRAND: 'brand',
    MANUFACTURER: 'manufacturer',
  },
  DOMAINTYPES: {
    BUYER: {
      SINGLE: 'buyer',
      PLURAL: 'buyers',
    },
    MANUFACTURER: {
      SINGLE: 'manufacturer',
      PLURAL: 'manufacturers',
    },
    COMPANY: {
      SINGLE: 'company',
      PLURAL: 'companies',
    },
  },
  IMAGES: {
    SECTION: {
      FACTORIES: {
        NAME: 'profile-sites-factories',
        CROP: {
          WIDTH: 100,
          HEIGHT: 100,
        },
      },
      BUYER_MINI_PROFILE: {
        NAME: 'miniprofile-cover',
        CROP: {
          WIDTH: 100,
          HEIGHT: 100,
        },
      },
      BUYER_MINI_PROFILE_LOGO: {
        NAME: 'miniprofile-logo',
        CROP: {
          WIDTH: 100,
          HEIGHT: 100,
        },
      },
      POST_FEED: {
        NAME: 'post-feed',
        CROP: {
          WIDTH: 800,
          HEIGHT: 400,
        },
      },
      CATALOGUE: {
        NAME: 'catalogue-post',
        CROP: {
          WIDTH: 800,
          HEIGHT: 400,
        },
      },
      USER_AVATAR: {
        NAME: 'user-avatar',
        CROP: {
          WIDTH: 150,
          HEIGHT: 150,
        },
      },
      STATIC_MAP: {
        NAME: 'static-map',
      },
    },
  },
  STATICIMAGESDOMAIN: 'STATICIMAGESDOMAIN',
  STATICFILESDOMAIN: 'STATICFILESDOMAIN',
  ENDPOINTS: 'ENDPOINTS',
  ERROR_LOG: 'ENDPOINTS.ERROR_LOG',
  CATEGORIES_JSON: 'ENDPOINTS.CATEGORIES_JSON',
  PROFILE: 'ENDPOINTS.PROFILE',
  CATEGORY_DETAILS: 'ENDPOINTS.CATEGORY_DETAILS',
  CATEGORY_COUNTRY_DETAILS: 'ENDPOINTS.CATEGORY_COUNTRY_DETAILS',
  CATEGORY_CITY_DETAILS: 'ENDPOINTS.CATEGORY_CITY_DETAILS',
  COUNTRY_FOOTER_INFO: 'ENDPOINTS.COUNTRY_FOOTER_INFO',
  CITY_FOOTER_INFO: 'ENDPOINTS.CITY_FOOTER_INFO',
  IMAGES_PATH: 'ENDPOINTS.IMAGES_PATH',
  ALIVE: 'ENDPOINTS.ALIVE',
  PROFILE_ENTITY: 'ENDPOINTS.PROFILE_ENTITY',
  FABRIC_GROUPS: 'ENDPOINTS.FABRIC_GROUPS',
  FABRIC_GROUP_DETAILS: 'ENDPOINTS.FABRIC_GROUP_DETAILS',
  FABRIC_GROUP_COUNTRIES: 'ENDPOINTS.FABRIC_GROUP_COUNTRIES',
  FABRIC_GROUP_COUNTRY_DETAILS: 'ENDPOINTS.FABRIC_GROUP_COUNTRY_DETAILS',
  FABRIC_GROUP_COUNTRY_CITY_DETAILS: 'ENDPOINTS.FABRIC_GROUP_COUNTRY_CITY_DETAILS',

  // tslint:disable-next-line: max-file-line-count
};
