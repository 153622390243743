import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONSTANTS } from '@app/common/constants';
import { UrlResolver } from '@app/common/url';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AnalyticsService } from './common/services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private _http: HttpClient,
    @Inject(UrlResolver) private _url: UrlResolver,
    @Inject(AnalyticsService) private _analytics: AnalyticsService
  ) {}

  public getAlive(): Observable<any> {
    const endpoint = this._url.get(CONSTANTS.ALIVE).replace('/fourall', '');

    return this._http.get(endpoint, { observe: 'response' }).pipe(
      first(),
      map(response => {
        this._analytics.isChina = response.headers.get('ischina');

        return response.headers.get('ischina') || false;
      })
    );
  }
}
