import { ModuleWithProviders, NgModule, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { Configurator } from './configurator';
import {
  BreaksPipe,
  ConsolePipe,
  FilterByPipe,
  FilterFieldPipe,
  NoSanitizeUrlPipe,
  NumeralPipe,
  SortByPipe,
} from './pipes';
import { CoreSettings, GenericType, Options, OptionsToken, StateToken } from './types';
import { UrlResolver } from './url';
import { InjectionToken } from '@angular/core/src/di';

export function factoryConfig(options: Options): Configurator {
  return new Configurator(options);
}

export function factoryUrlResolver(configurator: Configurator, platformId: string): UrlResolver {
  return new UrlResolver(configurator, platformId);
}

@NgModule({
  exports: [BreaksPipe, ConsolePipe, FilterByPipe, NoSanitizeUrlPipe, NumeralPipe, SortByPipe, FilterFieldPipe],
  declarations: [BreaksPipe, ConsolePipe, FilterByPipe, NoSanitizeUrlPipe, NumeralPipe, SortByPipe, FilterFieldPipe],
})
export class CommonLibModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CommonLibModule
  ) {
    if (parentModule) {
      throw new Error('CommonModule is already loaded. Import it in the AppModule only');
    }
  }

  public static provides(settings: CoreSettings<GenericType> = {}): ModuleWithProviders {
    return {
      ngModule: CommonLibModule,
      providers: [
        { provide: OptionsToken, useValue: settings.options },
        { provide: StateToken, useValue: settings.state },
        { provide: Configurator, useFactory: factoryConfig, deps: [OptionsToken] },
        { provide: UrlResolver, useFactory: factoryUrlResolver, deps: [Configurator, PLATFORM_ID] },
      ],
    };
  }
}

export * from './attribute';
export * from './collection';
export * from './configurator';
export * from './constants';
export * from './exceptions';
export * from './helpers';
export * from './metadata';
export * from './model';
export * from './pipes';
export * from './types';
export * from './url';
