import { HttpHeaders, HttpParams } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Model } from './model';

export interface GenericType {
  [key: string]: any;
}

export type CoreState<C> = { [K in keyof C]: C[K] };

export interface DomainHostType {
  HOST: string;
  KEY: string;
}

export interface RouteDescriptorType {
  name: string;
  prefix: string;
  uri: string;
  endpoint: string;
}

export type Options = GenericType;

export interface CoreSettings<S> {
  options?: Options;
  state?: CoreState<S>;
}

export const OptionsToken = new InjectionToken<Options>('[CONFIG] Json config');
export const StateToken = new InjectionToken<GenericType>('[INIT STATE] Initial state');
export const ApplicationState = new InjectionToken<CoreState<GenericType>>('[STATE] Application state');

/** The meta key for a model's options on a model class. */
export const MODEL_OPTIONS_META_KEY = 'data';

/** The meta key for a model attribute key list on a model class. */
export const MODEL_ATTRS_META_KEY = 'data:attrs';

/** The meta key for a service options on service class. */
export const SERVICE_OPTIONS_META_KEY = 'service';

/** The meta key for an attribute's validations, on a specific property. */
export const ATTR_VALIDATIONS_META_KEY = 'data:attrs:validations';

/**
 * Http body response signature
 *
 * @export
 * ResponseSignature
 */
export interface ResponseSignature {
  data: object | any[];
  meta: {
    page_size: number;
    length: number;
  };
  links: {
    self: string;
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  options: object | null;
}

export interface RestHttpOptions {
  headers?: HttpHeaders;
  observe?: any;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  model?: ModelConstructor<Model>;
}

export interface ModelOptions {
  named?: string;
}

export interface MachineResponse {
  message: string;
  rows_affected: number;
}

export interface ServiceOptions {
  model?: ModelConstructor<Model>;
  providers?: ModelConstructor<Model>[];
}

export interface ServiceMetadata {
  meta: {
    page_size: number;
    length: number;
  };
  links: {
    self: string;
    first: string;
    prev: string;
    next: string;
  };
  options: object | null;
}

/** Options for serializing a JSON object from a model instance. */
// tslint:disable-next-line:no-empty-interface
export interface SerializeOptions {
  /** The depth of associations to recursively serialize. */
  depth?: number;
}

/** Options for deserializing a JSON object to a model instance. */
export interface DeserializeOptions {
  /** The depth of associations to recursively serialize. */
  depth?: number;

  /** Whether to run validations to ensure the JSON data is valid. */
  validate?: boolean;
}

/** Options for constructing a model instance. */
export interface ModelConstructorOptions {
  /**
   * Whether the model constructor should merge all default values
   * onto the model instance.
   */
  defaults: boolean;
}

/** A value that is both a model class value and something that can construct a model. */
export type ModelConstructor<T extends Model> = typeof Model & { new (): T };

/** Options for validating a model instance. */
export interface ValidationOptions {
  /**
   * Whether to run validations that check whether required attributes are set.
   * This can be turned off to support partial validations.
   */
  required?: boolean;
}

/** A validation to be run on a model's property. */
export interface Validation {
  /** The function for validating the property. */
  cb: ValidationFunction;

  /** Any extra options to provided to the validation function. */
  options?: any;
}

/**
 * The internal type of an attribute.
 * These are mostly based off the Sequelize types,
 * as the main reason for designing the ModelSafe
 * library was for eventual integration with Sequelize.
 */
export enum InternalAttributeType {
  STRING,
  CHAR,
  TEXT,
  INTEGER,
  BIGINT,
  REAL,
  BOOLEAN,
  TIME,
  DATE,
  OBJECT,
  BLOB,
  ENUM,
  ARRAY,
}

/** The type of an attribute, with any options required. */
export interface AttributeType {
  /** The internal attribute type. */
  type: InternalAttributeType;

  /** Any validation function to perform checks on a instance's attribute value/type. */
  validate?: ValidationFunction;

  /** Any options for the attribute type. */
  options?: AttributeTypeOptions;
}

/** Options for the ENUM attribute type. */
export interface EnumAttributeTypeOptions {
  /** The values the enum attribute can have. */
  values: string[];
}

/** Options for the ARRAY attribute type. */
export interface ArrayAttributeTypeOptions {
  /* The attribute type this array will contain. */
  contained: AttributeType;
}

/**
 * The options for an attribute type.
 * This only required for a few of the trickier types, like enums and arrays.
 */
export type AttributeTypeOptions = EnumAttributeTypeOptions | ArrayAttributeTypeOptions;

/**
 * A validation function that can be used to validate a model property and its value.
 *
 * The function should return a promise that resolves when validation is successful,
 * or rejects with a `PropertyValidationError` if the property validation failed.
 *
 * Any errors rejected other than `PropertyValidationError` (i.e. if there's some unknown
 * fatal error) will be coerced into a `PropertyValidationError` with its type marked
 * as 'unknown'.
 */
export type ValidationFunction = (path: string, value: any, options?: any) => Promise<void>;

/** The options that can be defined for an attribute. */
export interface AttributeOptions {
  /** The type of the attribute. */
  type?: AttributeType;

  /**
   * Whether the attribute should be optional.
   * Attributes are required by default.
   */
  optional?: boolean;

  /** Whether the attribute is a primary key. */
  primary?: boolean;

  /** Whether the attribute is unique. */
  unique?: boolean;

  /** Whether the attribute is read-only. */
  readOnly?: boolean;

  /**
   * The default value for the attribute.
   * This will be automatically set on a model
   * when it is constructed for the first time.
   */
  defaultValue?: any;
}

/** The attributes defined on a model. */
export interface ModelAttributes {
  [key: string]: AttributeOptions;
}

export const ModelDataToken = new InjectionToken<any>('[DATA] Data Json.');
export const ModelConstructorOptionsToken = new InjectionToken<ModelConstructorOptions>('[DATA] Data options.');

export interface ToolboxButtonOptions {
  showroom: boolean;
}

export interface CountryInterface {
  id?: number;
  name: string;
  uid?: string;
  isoCode?: string;
}
export interface CityInterface {
  name: string;
  uid: string;
}
