import { NgModule } from '@angular/core';
import { NoSanitizeHtmlPipe } from '@app/common/pipes';
import { IconComponent } from './icon.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [IconComponent, NoSanitizeHtmlPipe],
  exports: [IconComponent],
  imports: [CommonModule],
})
export class IconModule {}
