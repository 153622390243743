import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS, makeSymbolPath, UrlResolver } from '@app/common';
import { SeoService } from '@app/common/services/seo.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomainService } from './domain.service';
import { DomainModel } from './domain.typings';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss'],
})
export class DomainComponent implements OnInit {
  public categories$: Observable<DomainModel>;
  public entityImagePath: string;
  public env = environment;

  constructor(
    private _router: Router,
    private _localService: DomainService,
    private _activeRoute: ActivatedRoute,
    private _meta: Meta,
    private _title: Title,
    private _seoService: SeoService,
    private _url: UrlResolver,
    @Inject(DOCUMENT) private _dom: Document
  ) {
    this._seoService.createCanonicalURL();

    const el = this._dom.createElement('p');
    const el_2 = this._dom.createElement('p');
    const html = '&#10003;';
    const html_2 = '&#9654;';
    el.innerHTML = html;
    el_2.innerHTML = html_2;
    this._title.setTitle('Manufacturers Directory | FOURSOURCE');
    this._meta.updateTag({
      name: 'description',
      content:
        ' Manufacturers Directory ' +
        el.innerHTML +
        ' Industry specific search ' +
        el.innerHTML +
        ' Access to a global database ' +
        el.innerHTML +
        ' Digital supplier showrooms ' +
        el_2.innerHTML +
        ' Sign up FREE on FOURSOURCE',
    });
  }

  public ngOnInit() {
    this._setEntityPaths();
    this.categories$ = this._localService.getCategories();
  }

  public onCardClick(category_uid: string) {
    this._router.navigate([`${category_uid}`], { relativeTo: this._activeRoute });
  }

  public getImgPath(id: number) {
    return this.entityImagePath + id + '.jpg';
  }

  public getBackgroundImg(uid: string) {
    return '/assets/img/manufacturerdefault.jpg';
  }

  private _setEntityPaths() {
    const staticDomain = this._url.get(makeSymbolPath([CONSTANTS.ENDPOINTS, CONSTANTS.STATICIMAGESDOMAIN]));

    this.entityImagePath = `${staticDomain}${environment.subdomain}/md/`;
  }
}
