import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'console',
})
export class ConsolePipe implements PipeTransform {
  public transform(value: any, group: string = 'HTML Console'): string {
    console.group(`TEMPLATE LOG: ${group}`);
    console.dir(value);
    console.groupEnd();

    return value;
  }
}

@Pipe({
  name: 'breaks',
})
export class BreaksPipe implements PipeTransform {
  public transform(value: number | string) {
    return String(value).replace(/\n/g, '<br />');
  }
}

@Pipe({
  name: 'numeral',
})
export class NumeralPipe implements PipeTransform {
  public transform(value: number | string) {
    return String(value).replace(/(.)(?=(\d{3})+$)/g, '$1,');
  }
}

@Pipe({
  name: 'noSanitizeHtml',
})
export class NoSanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  public transform(html: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}

@Pipe({
  name: 'noSanitizeUrl',
})
export class NoSanitizeUrlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  public transform(html: string): SafeUrl {
    return this._sanitizer.bypassSecurityTrustUrl(html);
  }
}

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  public transform(obj: any[], field: string, order: number = -1) {
    if (obj.length === 0 || Object.keys(obj[0]).indexOf(field) === -1) {
      return obj;
    }

    const temp = Object.assign(new Array(), obj);

    return temp.sort((obj1, obj2) => (obj1[field] > obj2[field] ? order : obj1[field] < obj2[field] ? order * -1 : 0));
  }
}

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  public transform(obj: any[], field: string, value: any) {
    if (!obj || obj.length === 0 || Object.keys(obj[0]).indexOf(field) === -1) {
      return obj;
    }

    return obj.filter(item => (Array.isArray(value) ? value.indexOf(item[field]) >= 0 : item[field] === value));
  }
}
@Pipe({
  name: 'filterField',
})
export class FilterFieldPipe implements PipeTransform {
  public transform(obj: any[], field: string) {
    const results = [];

    obj.forEach(res => {
      results.push(res[field]);
    });

    return results;
  }
}
