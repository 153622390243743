import * as tslib_1 from "tslib";
/**
 * An error raised during validation of a model's property.
 * This should be rejected with by a `ValidationFunction`.
 *
 * @see ValidationFunction
 */
var PropertyValidationError = /** @class */ (function (_super) {
    tslib_1.__extends(PropertyValidationError, _super);
    /**
     * Construct a property validation error.
     *
     * @param type The validation error type.
     * @param message The error message.
     */
    function PropertyValidationError(type, message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'PropertyValidationError';
        _this.stack = new Error().stack;
        _this.type = type;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = PropertyValidationError.prototype;
        return _this;
    }
    return PropertyValidationError;
}(Error));
export { PropertyValidationError };
var RestResponseError = /** @class */ (function (_super) {
    tslib_1.__extends(RestResponseError, _super);
    /**
     * Construct a http response error.
     *
     * @param type The validation error type.
     * @param message The error message.
     */
    function RestResponseError(type, message, status, body) {
        if (body === void 0) { body = null; }
        var _this = _super.call(this, message) || this;
        _this.name = 'RestResponseError';
        _this.stack = new Error().stack;
        _this.type = type;
        _this.status = status;
        _this.body = body;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = RestResponseError.prototype;
        return _this;
    }
    return RestResponseError;
}(Error));
export { RestResponseError };
var ServiceModelMissingError = /** @class */ (function (_super) {
    tslib_1.__extends(ServiceModelMissingError, _super);
    /**
     * Construct a http response error.
     *
     * @param type The validation error type.
     * @param message The error message.
     */
    function ServiceModelMissingError(type, message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'ServiceModelMissingError';
        _this.stack = new Error().stack;
        _this.type = type;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = ServiceModelMissingError.prototype;
        return _this;
    }
    return ServiceModelMissingError;
}(Error));
export { ServiceModelMissingError };
var ServicePayloadSignatureError = /** @class */ (function (_super) {
    tslib_1.__extends(ServicePayloadSignatureError, _super);
    /**
     * Construct a http response error.
     *
     * @param type The validation error type.
     * @param message The error message.
     */
    function ServicePayloadSignatureError(type, message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'ServicePayloadSignatureError';
        _this.stack = new Error().stack;
        _this.type = type;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = ServicePayloadSignatureError.prototype;
        return _this;
    }
    return ServicePayloadSignatureError;
}(Error));
export { ServicePayloadSignatureError };
/**
 * An error raised when a model instance fails to validate.
 *
 * This has a mapped errors object so that you access the errors by a specific model property.
 */
var ValidationError = /** @class */ (function (_super) {
    tslib_1.__extends(ValidationError, _super);
    /**
     * Construct a validation error.
     *
     * @param ctor The model constructor this error is for.
     * @param message The error message.
     * @param errors The model errors with all of the validation errors on it.
     * @param commonErrors Any common errors for a subset of the properties or the whole model.
     */
    function ValidationError(ctor, message, errors, commonErrors) {
        var _this = _super.call(this, message) || this;
        _this.name = 'ValidationError';
        _this.stack = new Error().stack;
        _this.ctor = ctor;
        _this.errors = errors;
        _this.commonErrors = commonErrors;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = ValidationError.prototype;
        return _this;
    }
    return ValidationError;
}(Error));
export { ValidationError };
var UrlResolverValidationError = /** @class */ (function (_super) {
    tslib_1.__extends(UrlResolverValidationError, _super);
    /**
     * Construct a property validation error.
     *
     * @param type The validation error type.
     * @param message The error message.
     */
    function UrlResolverValidationError(type, message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'UrlResolverValidationError';
        _this.stack = new Error().stack;
        _this.type = type;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = UrlResolverValidationError.prototype;
        return _this;
    }
    return UrlResolverValidationError;
}(Error));
export { UrlResolverValidationError };
var UrlResolverTestError = /** @class */ (function (_super) {
    tslib_1.__extends(UrlResolverTestError, _super);
    /**
     * Construct a property validation error.
     *
     * @param type The validation error type.
     * @param message The error message.
     */
    function UrlResolverTestError(type, message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'UrlResolverTestError';
        _this.stack = new Error().stack;
        _this.type = type;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = UrlResolverTestError.prototype;
        return _this;
    }
    return UrlResolverTestError;
}(Error));
export { UrlResolverTestError };
var SVGAttributeError = /** @class */ (function (_super) {
    tslib_1.__extends(SVGAttributeError, _super);
    /**
     * Construct a property validation error.
     *
     * @param type The validation error type.
     * @param message The error message.
     */
    function SVGAttributeError(type, message) {
        var _this = _super.call(this, message) || this;
        _this.name = 'SVGAttributeError';
        _this.stack = new Error().stack;
        _this.type = type;
        // Required in order for error instances to be able to use instanceof.
        // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
        _this.__proto__ = SVGAttributeError.prototype;
        return _this;
    }
    return SVGAttributeError;
}(Error));
export { SVGAttributeError };
