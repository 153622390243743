import { isDevMode } from '@angular/core';
import { find } from 'lodash';
import { Configurator } from '../configurator';
import { extractHostname } from '../helpers';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
var USER_TYPE;
(function (USER_TYPE) {
    USER_TYPE["GHOST"] = "GHOST";
    USER_TYPE["USER"] = "USER";
})(USER_TYPE || (USER_TYPE = {}));
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(config, _platformId, _dom) {
        this.config = config;
        this._platformId = _platformId;
        this._dom = _dom;
        this.ready = false;
        this.cachedEvents = [];
        this.domain = isPlatformBrowser(this._platformId) && this.checkAnalyticDomain();
        if (this.domain && this.enabled) {
            this.ready = true;
            this.setupTracker(this.domain.code);
        }
    }
    Object.defineProperty(AnalyticsService.prototype, "enabled", {
        get: function () {
            return !this.isChina && isPlatformBrowser(this._platformId);
        },
        enumerable: true,
        configurable: true
    });
    AnalyticsService.prototype.loadTagManager = function () {
        if (this.enabled && !isDevMode) {
            var script = document.createElement('script');
            script.text = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '" + environment.gtag + "');";
            var asyncScript = document.createElement('script');
            asyncScript.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=" + environment.gtag);
            asyncScript.setAttribute('async', 'async');
            document.head.prepend(asyncScript);
            document.head.prepend(script);
        }
    };
    AnalyticsService.prototype.setupTracker = function (trackID) {
        window.ga =
            window.ga ||
                function () {
                    (window.ga.q = window.ga.q || []).push(arguments);
                };
        window.ga.l = +new Date();
        window.ga('create', trackID, 'auto', { allowLinker: true });
        window.ga('require', 'linker');
        window.ga('linker:autoLink', ['m.foursource.com', 'app.foursource.com']);
        window.ga('require', 'cleanUrlTracker');
        window.ga('require', 'eventTracker');
        window.ga('require', 'outboundLinkTracker');
        window.ga('require', 'urlChangeTracker');
    };
    AnalyticsService.prototype.pageView = function (url) {
        if (url === void 0) { url = null; }
        if (this.enabled) {
            if (url === null) {
                url = window.location.href + window.location.search;
            }
            window.ga('set', 'page', url);
            window.ga('send', 'pageview');
        }
    };
    AnalyticsService.prototype.checkAnalyticDomain = function () {
        var href = window.location.href;
        var host = extractHostname(href);
        var domains = this.config.getOptionTree('ANALYTICS_DOMAINS', false);
        return find(domains, ['domain', host]) || false;
    };
    AnalyticsService.prototype.canUseIt = function () {
        return this.ready;
    };
    AnalyticsService.prototype.checkTagTracking = function () {
        if (window.gtag && this.enabled) {
            window.gtag('event', 'conversion', { send_to: this.domain.agency });
        }
        else if (!window.gtag) {
            this.cachedEvents.push(function () {
                window.gtag('event', 'conversion', { send_to: this.domain.agency });
            }.bind(this));
        }
    };
    AnalyticsService.prototype.dispatchEvents = function () {
        this.cachedEvents.forEach(function (event) {
            event();
        });
    };
    AnalyticsService.prototype.setupAnalytics = function () {
        if (this.canUseIt()) {
            this.loadTagManager();
            this.dispatchEvents();
            this.pageView();
        }
    };
    return AnalyticsService;
}());
export { AnalyticsService };
