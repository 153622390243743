/** An error with a single property of a model. */
import { Model } from './model';
import { ModelConstructor } from './types';

export interface PropertyError {
  /**
   * The type of error. This is used to specify what particular validation
   * raised the property error. This is the best thing to use for generating
   * the user-appropriate error message for a property error message
   * generated by a validation, which may not be particularly user-friendly on its own.
   */
  type: string;

  /** The error message generated by a validation. */
  message: string;
}

/**
 * Errors with the properties of a model. Each model property can have
 * an array of errors.
 */
export type ModelErrors<T extends Model> = { [P in keyof T]?: PropertyError[] };

/**
 * Errors common to a subset of a model's properties or the whole model.
 *
 * The suggested usage for this would be for when there's an error that affects
 * multiple properties, such as when there's a shared unique key failure.
 * It's also useful for providing errors that are general to the entire model instance,
 * such as when there's some business logic error.
 */
export interface CommonModelError {
  /**
   * The type of the common error.
   *
   * @see PropertyError
   */
  type: string;

  /**
   * Properties referenced by the common error, e.g. which
   * properties triggered a specific error case.
   */
  props?: string[];

  /** The common error message, either for the joint properties or for the entire model. */
  message: string;
}

/**
 * An error raised during validation of a model's property.
 * This should be rejected with by a `ValidationFunction`.
 *
 * @see ValidationFunction
 */
export class PropertyValidationError extends Error {
  /**
   * The validation error type.
   *
   * @see PropertyError
   */
  public type: string;

  /**
   * Construct a property validation error.
   *
   * @param type The validation error type.
   * @param message The error message.
   */
  constructor(type: string, message: string) {
    super(message);

    this.name = 'PropertyValidationError';
    this.stack = new Error().stack;
    this.type = type;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = PropertyValidationError.prototype;
  }
}

export class RestResponseError extends Error {
  /**
   * The http error type.
   *
   * @see PropertyError
   */
  public type: string;
  public status: number;
  public body: object;

  /**
   * Construct a http response error.
   *
   * @param type The validation error type.
   * @param message The error message.
   */
  constructor(type: string, message: string, status: number, body: object = null) {
    super(message);

    this.name = 'RestResponseError';
    this.stack = new Error().stack;
    this.type = type;
    this.status = status;
    this.body = body;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = RestResponseError.prototype;
  }
}

export class ServiceModelMissingError extends Error {
  /**
   * The http error type.
   *
   * @see PropertyError
   */
  public type: string;

  /**
   * Construct a http response error.
   *
   * @param type The validation error type.
   * @param message The error message.
   */
  constructor(type: string, message: string) {
    super(message);

    this.name = 'ServiceModelMissingError';
    this.stack = new Error().stack;
    this.type = type;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = ServiceModelMissingError.prototype;
  }
}

export class ServicePayloadSignatureError extends Error {
  /**
   * The http error type.
   *
   * @see PropertyError
   */
  public type: string;

  /**
   * Construct a http response error.
   *
   * @param type The validation error type.
   * @param message The error message.
   */
  constructor(type: string, message: string) {
    super(message);

    this.name = 'ServicePayloadSignatureError';
    this.stack = new Error().stack;
    this.type = type;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = ServicePayloadSignatureError.prototype;
  }
}

/**
 * An error raised when a model instance fails to validate.
 *
 * This has a mapped errors object so that you access the errors by a specific model property.
 */
export class ValidationError<T extends Model> extends Error {
  /** The model constructor. */
  public ctor: ModelConstructor<T>;

  /** The model errors. */
  public errors?: ModelErrors<T>;

  /** The model errors that are common across the entire model or multiple properties. */
  public commonErrors?: CommonModelError[];

  /**
   * Construct a validation error.
   *
   * @param ctor The model constructor this error is for.
   * @param message The error message.
   * @param errors The model errors with all of the validation errors on it.
   * @param commonErrors Any common errors for a subset of the properties or the whole model.
   */
  constructor(ctor: ModelConstructor<T>, message: string, errors?: ModelErrors<T>, commonErrors?: CommonModelError[]) {
    super(message);

    this.name = 'ValidationError';
    this.stack = new Error().stack;
    this.ctor = ctor;
    this.errors = errors;
    this.commonErrors = commonErrors;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = ValidationError.prototype;
  }
}

export class UrlResolverValidationError extends Error {
  /**
   * The validation error type.
   *
   * @see PropertyError
   */
  public type: string;

  /**
   * Construct a property validation error.
   *
   * @param type The validation error type.
   * @param message The error message.
   */
  constructor(type: string, message: string) {
    super(message);

    this.name = 'UrlResolverValidationError';
    this.stack = new Error().stack;
    this.type = type;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = UrlResolverValidationError.prototype;
  }
}

export class UrlResolverTestError extends Error {
  /**
   * The validation error type.
   *
   * @see PropertyError
   */
  public type: string;

  /**
   * Construct a property validation error.
   *
   * @param type The validation error type.
   * @param message The error message.
   */
  constructor(type: string, message: string) {
    super(message);

    this.name = 'UrlResolverTestError';
    this.stack = new Error().stack;
    this.type = type;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = UrlResolverTestError.prototype;
  }
}

export class SVGAttributeError extends Error {
  /**
   * The validation error type.
   *
   * @see PropertyError
   */
  public type: string;

  /**
   * Construct a property validation error.
   *
   * @param type The validation error type.
   * @param message The error message.
   */
  constructor(type: string, message: string) {
    super(message);

    this.name = 'SVGAttributeError';
    this.stack = new Error().stack;
    this.type = type;

    // Required in order for error instances to be able to use instanceof.
    // SEE: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md
    (this as any).__proto__ = SVGAttributeError.prototype;
  }
}
