import { Options } from './types';
import { isObject, mapKeys, set, startsWith } from 'lodash';
/**
 * Class to centralized configurations values.
 *
 * @export
 * Configurator
 */
var Configurator = /** @class */ (function () {
    function Configurator(options) {
        this.repository = {};
        this.options = options || {};
    }
    Configurator.prototype.setOption = function (name, value) {
        this.repository[name] = value;
    };
    /**
     * Get a configuration value from the collection.
     *
     */
    Configurator.prototype.getOption = function (name, defaults) {
        if (defaults === void 0) { defaults = null; }
        return this.hasOption(name) ? this.repository[name] : defaults;
    };
    Configurator.prototype.getOptionTree = function (rootKey, fromRoot) {
        if (fromRoot === void 0) { fromRoot = true; }
        var tree = {};
        mapKeys(this.options, function (value, key) {
            if (startsWith(key, rootKey)) {
                set(tree, key, value);
            }
            return key;
        });
        return fromRoot ? tree : tree[rootKey];
    };
    Object.defineProperty(Configurator.prototype, "options", {
        get: function () {
            return this.repository;
        },
        set: function (opt) {
            this.flat(opt);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Verify if option name exists on the collection.
     *
     */
    Configurator.prototype.hasOption = function (name) {
        return this.repository.hasOwnProperty(name);
    };
    /**
     * Converts a tree object keys in flat
     * key string in one level.
     *
     * {
     *  name: '',
     *  profile: {
     *    email: ''
     *  }
     * }
     *
     * to: {'name': '', 'profile.email': ''}
     *
     */
    Configurator.prototype.flat = function (config, key) {
        var _this = this;
        if (key === void 0) { key = ''; }
        var path = +(key === '') ? key : key + '.';
        Object.keys(config).forEach(function (keyId) {
            if (isObject(config[keyId])) {
                _this.flat(config[keyId], path + keyId);
            }
            else {
                _this.setOption("" + (path + keyId), config[keyId]);
            }
        });
    };
    return Configurator;
}());
export { Configurator };
