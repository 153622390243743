<nav class="navbar navbar-expand-xl fixed-top navbar-light">
  <a class="navbar-brand" href="https://www.foursource.com" alt="Foursource">
    <img class="img-fluid" src="/assets/img/foursource-logo-black.svg" alt="Foursource">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" href="https://www.foursource.com/features" alt="How it works">How it works</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.foursource.com/members" alt="Our Members">Our Members</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.foursource.com/details/buyers" alt="For Buyers">For Buyers</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.foursource.com/details/manufacturers" alt="For Manufacturers">For Manufacturers</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.foursource.com/pricing" alt="Pricing">Pricing</a>
      </li>
      <li>
        <a class="btn btn-success" href="https://www.foursource.com/signup">Join Now</a>
      </li>
      <li>
        <a class="btn btn-default" href="https://www.foursource.com/login">Login</a>
      </li>
    </ul>
  </div>
</nav>
