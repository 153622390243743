import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UrlResolverTestError, UrlResolverValidationError } from './exceptions';
import { find, head, isEmpty } from 'lodash';

import { Configurator } from './configurator';
import { DomainHostType, GenericType, RouteDescriptorType } from './types';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class UrlResolver {
  public static OPTION_ENDPOINT_NAME = 'ENDPOINTS';
  public static OPTIONS_DOMAIN_NAME = 'DOMAINS_API';

  public prefixes: DomainHostType[];
  public routes: Map<string, RouteDescriptorType>;

  constructor(
    @Inject(Configurator) private configurator: Configurator,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.routes = new Map();
    this.init();
  }

  /**
   * Init setup for resolving routes collection.
   *
   */
  public init(): void {
    const { keys } = Object;
    const endpoints = this.configurator.getOptionTree<GenericType>(UrlResolver.OPTION_ENDPOINT_NAME, false);

    this.prefixes = this.configurator.getOptionTree<DomainHostType[]>(UrlResolver.OPTIONS_DOMAIN_NAME, false);

    keys(endpoints).forEach(key => this.setupRoute(key, endpoints[key]));
  }

  /**
   * Setup regex routing and add it to Map.
   *
   */
  public setupRoute(name: string, uri: string) {
    const verbal = new RegExp(/^@\w+\:/);
    const search = head(verbal.exec(uri)) || '';

    const urlDescriptor = {
      name,
      prefix: search,
      uri,
      endpoint: uri.replace(search, ''),
    } as RouteDescriptorType;

    this.addRoute(name, urlDescriptor);
  }

  /**
   * Add route to Map.
   *
   */
  public addRoute(name: string, descriptor: RouteDescriptorType) {
    this.routes.set(name, descriptor);
  }

  /**
   * Get the route from Map and resolve domain host. Pass parameters
   * to complete dynamic arguments on route, even overriding domain host is possible.
   *
   */
  public get(name: string, params: GenericType = null, domain: string = null) {
    if (name.includes('.')) {
      name = name.substr(name.indexOf('.') + 1);
    }

    const routeDescriptor: RouteDescriptorType = this.routes.get(name);

    if (isEmpty(routeDescriptor)) {
      throw new UrlResolverValidationError(name, 'Uri is not setup on mappings.');
    } else {
      const resolved = this.resolve(routeDescriptor, params, domain);

      return resolved.url;
    }
  }

  /**
   * Resolve host domain and route.
   *
   */
  public resolve(descriptor: RouteDescriptorType, args: GenericType, host = '') {
    const regex = this.expression(descriptor.endpoint);
    const test = regex.test(descriptor.endpoint);
    const prefix = descriptor.prefix.substring(0, descriptor.prefix.length - 1);

    if (test) {
      let url = null;
      const parameters = [];

      regex
        .exec(descriptor.endpoint)
        .slice(1)
        .forEach(arg => {
          if (arg) {
            parameters.push(decodeURIComponent(arg));
          }

          if (args && arg) {
            url = url
              ? url.replace(arg, args[arg.substring(1, arg.length)])
              : descriptor.endpoint.replace(arg, args[arg.substring(1, arg.length)]);
          }
        });

      const domain = find<DomainHostType>(this.prefixes, ['KEY', prefix]);

      let domainHost = isEmpty(host) ? domain.HOST || '' : host;
      if (isPlatformServer(this.platformId)) {
        domainHost = domainHost.replace('.foursource.com', '').replace('https', 'http');
      }

      return {
        name: descriptor.name,
        host: domainHost,
        url: url ? `${domainHost}${url}` : `${domainHost}${descriptor.endpoint}`,
        params: args,
        regex: regex.source,
      };
    } else {
      throw new UrlResolverTestError(descriptor.name, 'UrlResolver test didnt match any url.');
    }
  }

  /**
   * Macth route arguments.
   *
   */
  public expression(route: string) {
    const splatParam = /\*\w+/g;
    const namedParam = /(\(\?)?:\w+/g;
    const optionalParam = /\((.*?)\)/g;
    const escapeRegExp = /[\-{}\[\]+?.,\\\^$|#\s]/g;

    route = route
      .replace(escapeRegExp, '\\$&')
      .replace(optionalParam, '(?:$1)?')
      .replace(namedParam, (match, optional) => {
        return optional ? match : '([^/?]+)';
      })
      .replace(splatParam, '([^?]*?)');

    return new RegExp('^' + route + '(?:\\?([\\s\\S]*))?$');
  }
}
