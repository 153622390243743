import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONSTANTS } from '@app/common/constants';
import { UrlResolver } from '@app/common/url';
import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DomainCountryDetailsModel, DomainDetailsModel, DomainModel, FooterInfoModel } from './domain.typings';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  constructor(private _http: HttpClient, @Inject(UrlResolver) private _url: UrlResolver) {}

  public getCategories(): Observable<DomainModel> {
    const endpoint = this._url.get(CONSTANTS.CATEGORIES_JSON, { subdomain: environment.subdomain });

    return this._http.get(endpoint).pipe(
      first(),
      pluck('data')
    );
  }

  public getCategoryDetails(category_uid: string): Observable<DomainDetailsModel> {
    const endpoint = this._url.get(CONSTANTS.CATEGORY_DETAILS, {
      subdomain: environment.subdomain,
      uid: category_uid,
    });

    return this._http.get(endpoint).pipe(
      first(),
      pluck('data')
    );
  }

  public getCategoryCountryDetails(params: {
    category_uid: string;
    country_uid: string;
    city_uid: string;
  }): Observable<DomainCountryDetailsModel> {
    const endpoint =
      params && params.city_uid
        ? this._url.get(CONSTANTS.CATEGORY_CITY_DETAILS, {
            subdomain: environment.subdomain,
            category: params.category_uid,
            country: params.country_uid,
            city: params.city_uid,
          })
        : this._url.get(CONSTANTS.CATEGORY_COUNTRY_DETAILS, {
            subdomain: environment.subdomain,
            category: params.category_uid,
            country: params.country_uid,
          });

    return this._http.get(endpoint).pipe(
      first(),
      pluck('data')
    );
  }

  public getFooterInfo(params: {
    category_uid: string;
    country_uid: string;
    city_uid: string;
  }): Observable<FooterInfoModel> {
    const endpoint =
      params && params.city_uid
        ? this._url.get(CONSTANTS.COUNTRY_FOOTER_INFO, {
            subdomain: environment.subdomain,
            category: params.category_uid,
            country: params.country_uid,
          })
        : this._url.get(CONSTANTS.CITY_FOOTER_INFO, {
            subdomain: environment.subdomain,
            category: params.category_uid,
            country: params.country_uid,
            city: params.city_uid,
          });

    return this._http.get(endpoint).pipe(
      first(),
      pluck('data')
    );
  }
}
