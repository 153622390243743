import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HandleHttpErrorInterceptor implements HttpInterceptor {
  constructor(private _router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Promise<boolean>>> {
    return next.handle(req).pipe(
      tap({
        error: error => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 404:
                if (req.url.includes('/fabric-suppliers') || req.url.includes('/profile/fabrics')) {
                  return this._router.navigate(['/fabrics']);
                }

                return this._router.navigate(['/manufacturers']);
              default:
                break;
            }
          }
        },
      })
    );
  }
}
