import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(@Inject(DOCUMENT) private _dom: Document) {}

  public createCanonicalURL(): void {
    const canonical = this._dom.querySelector('link[rel="canonical"]');
    canonical.setAttribute('href', environment.app_url + this._dom.location.pathname);
  }
}
