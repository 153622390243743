import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CategoriesModule } from './containers/categories/categories.module';
import { DomainModule } from './containers/domain/domain.module';

const subdomain = environment.subdomain;

// forced to do a single return statment
export const entryModule =
  subdomain === 'services'
    ? DomainModule
    : subdomain === 'treatments'
    ? DomainModule
    : subdomain === 'fabrics'
    ? DomainModule
    : subdomain === 'materials'
    ? DomainModule
    : CategoriesModule;

const routes: Routes = [
  {
    path: '**',
    redirectTo: 'manufacturers'
  }
];

@NgModule({
  imports: [
    entryModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
