var _this = this;
import * as tslib_1 from "tslib";
import { isDate, isArray, isString, isNumber, isInteger, isBoolean, isPlainObject } from 'lodash';
import { InternalAttributeType } from './types';
import { PropertyValidationError } from './exceptions';
/**
 * Build an attribute from an internal attribute type and options.
 *
 * @param type The internal attribute type.
 * @param validate An attribute type/value validation function if required.
 * @param options Any attribute type options if required.
 */
function buildAttributeType(type, validate, options) {
    var attrType = { type: type };
    if (validate) {
        attrType.validate = validate;
    }
    if (options) {
        attrType.options = options;
    }
    return attrType;
}
/**
 * A string attribute type.
 *
 * The type only validates that the value is a string.
 * The property type should be declared as `string`.
 */
export var STRING = buildAttributeType(InternalAttributeType.STRING, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isString(value)) {
            throw new PropertyValidationError('attribute.string', 'Not a string');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A character attribute type.
 *
 * The type validates that there is only a single character in the string.
 * The property type should be declared as `string`.
 */
export var CHAR = buildAttributeType(InternalAttributeType.CHAR, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isString(value) || value.length !== 1) {
            throw new PropertyValidationError('attribute.char', 'Not a character');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A text attribute type. In most ModelSafe integrations,
 * this will translate to larger storage than a string.
 *
 * The type only validates that the value is a string.
 * The property type should be declared as `string`.
 */
export var TEXT = buildAttributeType(InternalAttributeType.TEXT, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isString(value)) {
            throw new PropertyValidationError('attribute.text', 'Not a string');
        }
        return [2 /*return*/];
    });
}); });
/**
 * An integer attribute type.
 *
 * The property type should be declared as `number`.
 */
export var INTEGER = buildAttributeType(InternalAttributeType.INTEGER, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isInteger(value)) {
            throw new PropertyValidationError('attribute.integer', 'Not an integer number');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A BigInt attribute type. In some integrations,
 * this is represented as a string property to prevent precision loss.
 *
 * The type is not validated.
 * The property type should be declared as `string` or similar.
 */
export var BIGINT = buildAttributeType(InternalAttributeType.BIGINT);
/**
 * A float attribute type.
 *
 * The type only validates that the value is a real number.
 * The property type should be declared as `number`.
 */
export var REAL = buildAttributeType(InternalAttributeType.REAL, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isNumber(value)) {
            throw new PropertyValidationError('attribute.real', 'Not a real number');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A boolean attribute type.
 *
 * The property type should be declared as `boolean`.
 */
export var BOOLEAN = buildAttributeType(InternalAttributeType.BOOLEAN, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isBoolean(value)) {
            throw new PropertyValidationError('attribute.boolean', 'Not a boolean');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A timestamp (with or without a date portion) attribute type.
 *
 * The property type should be declared as `Date`.
 */
export var TIME = buildAttributeType(InternalAttributeType.TIME, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isDate(value) || isNaN(value.getTime())) {
            throw new PropertyValidationError('attribute.time', 'Not a time');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A date attribute type.
 *
 * The property type should be declared as `Date`.
 */
export var DATE = buildAttributeType(InternalAttributeType.DATE, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isDate(value) || isNaN(value.getTime())) {
            throw new PropertyValidationError('attribute.date', 'Not a date');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A plain JavaScript object type.
 *
 * The property type should be declared as `object`.
 */
export var OBJECT = buildAttributeType(InternalAttributeType.OBJECT, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        if (!isPlainObject(value)) {
            throw new PropertyValidationError('attribute.object', 'Not an object');
        }
        return [2 /*return*/];
    });
}); });
/**
 * A blob (binary) attribute type.
 *
 * This type is not validated.
 * The property type should be declared as `ArrayBuffer` or similar.
 */
export var BLOB = buildAttributeType(InternalAttributeType.BLOB);
/**
 * An enum attribute type, which is an attribute that can be one of
 * a number of possible string values.
 *
 * @param values The values to be available in the enum.
 * @returns The ENUM attribute type.
 */
export function ENUM(values) {
    var _this = this;
    return buildAttributeType(InternalAttributeType.ENUM, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            if (values.indexOf(value) === -1) {
                throw new PropertyValidationError('attribute.enum', 'Not a known enumeration value');
            }
            return [2 /*return*/];
        });
    }); }, { values: values });
}
/**
 * An array attribute type, which is represented as an attribute
 * that can contain a number of other attributes.
 *
 * @param contained The attribute type the array will contain.
 * @returns The ARRAY attribute type.
 */
export function ARRAY(contained) {
    var _this = this;
    return buildAttributeType(InternalAttributeType.ARRAY, function (_path, value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            if (!isArray(value)) {
                throw new PropertyValidationError('attribute.array', 'Not an array');
            }
            return [2 /*return*/];
        });
    }); }, { contained: contained });
}
