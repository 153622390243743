import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public footerInfo: {} = {
    footerCopyright: [
      {
        title: '2019 FOURSOURCE Group GmbH',
      },
    ],
    footerMenu: [
      {
        links: [
          {
            link: 'https://www.foursource.com/about-us',
            title: 'About Us',
          },
          {
            link: 'https://www.foursource.com/career',
            title: 'Career',
          },
        ],
        title: 'Company',
      },
      {
        links: [
          {
            link: 'https://www.foursource.com/code-of-conduct',
            title: 'Code of Conduct',
          },
          {
            link: 'https://www.foursource.com/imprint',
            title: 'Imprint',
          },
          {
            link: 'https://www.foursource.com/terms-conditions',
            title: 'Terms \u0026 Conditions',
          },
          {
            link: 'https://www.foursource.com/privacy-policy',
            title: 'Privacy Policy',
          },
        ],
        title: 'Legal',
      },
      {
        links: [
          {
            link: 'https://www.foursource.com/buyers',
            title: 'For Buyers',
          },
          {
            link: 'https://www.foursource.com/manufacturers',
            title: 'For Manufacturers',
          },
          {
            link: 'https://www.foursource.com/features',
            title: 'How it works',
          },
        ],
        title: 'Product',
      },
      {
        links: [
          {
            icon: '',
            link: 'https://www.foursource.com/contact-us',
            title: 'Contact Us',
          },
        ],
        title: 'Contact',
      },
    ],
    footerSocialNetworks: [
      {
        icon: 'fb',
        link: 'https://www.facebook.com/foursource',
        title: 'facebook',
      },
      {
        icon: 'tw',
        link: 'https://twitter.com/Foursource',
        title: 'twitter',
      },
      {
        icon: 'in',
        link: 'https://www.linkedin.com/company/foursource',
        title: 'linkedin',
      },
    ],
  };
}
