import { FormGroup } from '@angular/forms';
// import { Action } from '@foursource/core/state/actions';
// import { Store } from '@foursource/core/state/store';
import { camelCase, find, merge } from 'lodash';

import { CONSTANTS } from './constants';

// const STORE = require('store');

export function uniqueID() {
  function chr4() {
    return Math.random()
      .toString(16)
      .slice(-4);
  }

  return chr4() + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + chr4() + chr4();
}

export function successMessage(message: string) {
  const html =
    '<h2 class="notification-message"><img class="check-img" src="/assets/img/checkSuccess.png">' + message + '</h2>';

  return html;
}

export function errorMessage(message: string) {
  const html =
    '<h2 class="notification-message"><img class="check-img" src="/assets/img/checkError.png">' + message + '</h2>';

  return html;
}

export function extractHostname(url: string) {
  let hostname;

  if (url.indexOf('://') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  hostname = hostname.split(':')[0];

  return hostname;
}

export function getCookie(name: string) {
  const cookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

  return cookie ? cookie[2] : null;
}
// domain=.foursource.com
export function setCookie(name: string, value: string, expire: number, domain: string = '.foursource.com') {
  const date = new Date();
  date.setTime(date.getTime() + expire);

  document.cookie = `${name}=${value};domain=${domain};path=/;expires=${date.toUTCString()}`;
}

export function deleteCookie(name: string, value = '', expires = -1, domain = '.foursource.com') {
  setCookie(name, value, expires, domain);
}

export function cookieDomain(url: string): string {
  const domain = extractHostname(url);
  const split = domain.split('.');

  return split[split.length - 2] + '.' + split[split.length - 1];
}

// return an array of objects according to key, value, or key and value matching
export function where(obj: {}, key: string, val: any): any[] {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }
    if (typeof obj[i] === 'object') {
      objects = objects.concat(where(obj[i], key, val));
    } else {
      // if key matches and value matches or if key matches and value
      // is not passed (eliminating the case where key matches but passed value does not)
      if ((i === key && obj[i] === val) || (i === key && val === '')) {
        //
        objects.push(obj);
      } else if (obj[i] === val && key === '') {
        // only add if the object is not already in the array
        if (objects.lastIndexOf(obj) === -1) {
          objects.push(obj);
        }
      }
    }
  }

  return objects;
}

// return an array of values that match on a certain key
export function whereKey(obj: {}, key: string): any[] {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }
    if (typeof obj[i] === 'object') {
      objects = objects.concat(whereKey(obj[i], key));
    } else if (i === key) {
      objects.push(obj[i]);
    }
  }

  return objects;
}

// return an array of keys that match on a certain value
export function whereValue(obj, val): any[] {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }
    if (typeof obj[i] === 'object') {
      objects = objects.concat(whereValue(obj[i], val));
    } else if (obj[i] === val) {
      objects.push(i);
    }
  }

  return objects;
}

export function functionName(ctor: Function): string {
  let name = ctor.name;

  if (!name) {
    let str = ctor.toString();

    str = str.substr('function '.length);
    str = str.substr(0, str.indexOf('('));

    name = str;
  }

  return camelCase(name);
}

export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

export function coerceNumberProperty(value: any, fallbackValue = 0) {
  // parseFloat(value) handles most of the cases we're interested in (it treats null, empty string,
  // and other non-number values as NaN, where Number just uses 0) but it considers the string
  // '123hello' to be a valid number. Therefore we also check if Number(value) is NaN.
  return isNaN(parseFloat(value as any)) || isNaN(Number(value)) ? fallbackValue : Number(value);
}

export function scroll(element: Element, toCenter: boolean = false, toStart: boolean = false) {
  element.scrollIntoView({
    block: toCenter ? 'center' : toStart ? 'start' : 'nearest',
    inline: toCenter ? 'center' : toStart ? 'start' : 'nearest',
    behavior: 'smooth',
  });
}

export function isEmpty(value: any): boolean {
  const isEmptyObject = function(a) {
    if (typeof a.length === 'undefined') {
      // it's an Object, not an Array
      const hasNonempty = Object.keys(a).some(function nonEmpty(element) {
        return !isEmpty(a[element]);
      });

      return hasNonempty ? false : isEmptyObject(Object.keys(a));
    }

    return !a.some(function nonEmpty(element) {
      // check if array is really not empty as JS thinks
      return !isEmpty(element); // at least one element should be non-empty
    });
  };

  return (
    value === false ||
    typeof value === 'undefined' ||
    value == null ||
    value.length > 0 ||
    (typeof value === 'object' && isEmptyObject(value))
  );
}

export function flagsFormat(state) {
  if (!state.id) {
    return state.text;
  }

  const $state = '';
  // const $state = $(
  //   '<span><i class="flag flag-' + state.element.value.toLowerCase() + '"></i> ' + state.text + '</span>'
  // );

  return $state;
}

export function makeSymbolPath(symbols: string[]): string {
  return symbols.join('.');
}

export function humanizeBytes(bytes: number): string {
  if (bytes === 0) {
    return '0 Byte';
  }

  const k = 1024;
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i: number = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i] + '/s';
}

export function getUserFromToken(token: string): { exp: string; iss: string; sub: string } {
  return JSON.parse(atob(token.split('.')[1]));
}

// export function stateChange<T>(slice: Store<T>, payload: any) {
//   const action = new Action<any>();
//   const uiReducer = (state, res) => {
//     return merge(state, res);
//   };

//   slice.addReducer(action, uiReducer);
//   action.next(payload);
// }

export function jsonParse(stringify: string) {
  try {
    return JSON.parse(stringify);
  } catch (error) {
    return null;
  }
}

// export function getLocalStorage(key?: string): any {
//   return key ? STORE.get(key) : STORE.getAll();
// }

// export function getSectionByKey(key: string): { key: string; name: string; tab: string; hash: string } {
//   return find(CONSTANTS.SECTIONS, ['key', key]);
// }

/**
 * Marks all controls in a form group as touched
 * @param formGroup - The group to mark as touched
 */
// export function markFormGroupTouched(formGroup: FormGroup): void {
//   if (!formGroup.controls) {
//     return;
//   }
//   Object.values(formGroup.controls).forEach(control => {
//     control.markAsTouched();

//     if ((<FormGroup>control).controls) {
//       Object.values((<FormGroup>control).controls).forEach(c => this.markFormGroupTouched(c));
//     }
//   });
// }

export function scrollToInvalid(form: HTMLFormElement) {
  const elements = form.elements;
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].nodeName === 'FIELDSET') {
      continue;
    }

    const el = elements[i].closest('.ng-invalid');

    if (el && el.nodeName !== 'FORM') {
      scroll(el, true);
      break;
    }
  }
}

// export function trimFormGroup(form: FormGroup) {
//   Object.values(form.controls).forEach(control => {
//     if (typeof control.value === 'string' && control.value.indexOf('fakepath\\') === -1) {
//       control.setValue(control.value.trim());
//     }

//     if ((<FormGroup>control).controls) {
//       trimFormGroup(<FormGroup>control);
//     }
//   });
// }

export function isNumber(ev: KeyboardEvent): boolean {
  return (ev.charCode >= 48 && ev.charCode <= 57) || ev.charCode === 0 || ev.charCode === 8;
}

export function getDefaultLogo(domain: string): string {
  return domain === CONSTANTS.DOMAINTYPES.BUYER.SINGLE
    ? '/assets/img/logodefault.png'
    : '/assets/img/manufacturer-logo.png';
}

export function btoaImplementation(str: string) {
  try {
    return btoa(str);
  } catch (err) {
    return Buffer.from(str).toString('base64');
  }
}
