import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { CONFIGURATION } from './app.config';
import { CommonLibModule } from './common';
import { AnalyticsService } from './common/services/analytics.service';
import { HandleHttpErrorInterceptor } from './common/services/handle-http-error.service';
import { FooterComponent } from './components/footer/footer.component';
import { IconModule } from './components/icon/icon.module';
import { NavbarComponent } from './components/navbar/navbar.component';

@NgModule({
  declarations: [AppComponent, NavbarComponent, FooterComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonLibModule.provides({
      options: CONFIGURATION,
    }),
    IconModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HandleHttpErrorInterceptor, multi: true }, AnalyticsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
