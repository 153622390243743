<ng-container *ngIf="(categoryCountryDetails$ | async) as info else loadingState">
  <header class="w-100">
    <img class="w-100" height="400px" [src]="getBackgroundImg(info.country.isoCode )" alt="">
  </header>
  <div class="container">
    <div class="content-container-fat main-container pb-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/manufacturers'">Manufacturers directory</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">
              <a [routerLink]="'/manufacturers/'+info.uid">{{ info.name }}</a>
          </li>
          <li class="breadcrumb-item" [class.active]="!info.city" aria-current="page">
            <span *ngIf="!info.city">{{info.country.name}}</span>
            <a *ngIf="info.city" [routerLink]="'/manufacturers/'+info.uid+'/'+info.country.uid">{{ info.country.name }}</a>
          </li>
          <li *ngIf="info.city" class="breadcrumb-item active" aria-current="page">{{info.city.name}}</li>
        </ol>
      </nav>
      <div class="header-content row">
        <div class="col-md-3">
          <img class="w-100" [src]="getImgPath(info.id)" alt="">
        </div>
        <div class="col-md-9 text-left">
          <h1 class="headline"><b>{{ info.name}} Manufacturers {{ info.city ? 'from '+info.city.name : ''}} in {{ info.country.name }}</b></h1>
          <h2 class="menu">Are you searching for {{ info.name }} manufacturers in {{ info.city ? info.city.name + ' of' : ''}} {{ info.country.name}} in the apparel industry?</h2>
          <p class="menu font-weight-normal">Then you can find below {{info.totalManufacturers}} {{info.name}} manufacturers. Looking for more? Check out other {{info.name}} manufacturers in our network from {{info.totalCountries}} countries in {{info.totalCities}} cities all over the world.</p>
          <p class="menu">FOURSOURCE is the world’s largest sourcing network in the apparel industry.</p>
          <a href="https://foursource.com/signup" target="_blank" rel="noopener" class="btn btn-success">Join for free</a>
        </div>
      </div>
      <div class="body-content">
        <h3 class="menu">{{ info.name }} manufacturer from {{ info.city ? info.city.name + ' in' : ''}} {{ info.country.name }}:</h3>
          <div class="row">
            <a *ngFor="let manufacturer of info.list; let i = index;" [href]="environment.profile_domain + '/profile/' + manufacturer.uid" class="manufacturer col-md-4" [class.city-background]="i%2 === 0">
              {{manufacturer.name}}
            </a>
          </div>
      </div>
    </div>
    <ng-container *ngIf="(footerInfoDetails$ | async) as footerInfo">
      <div *ngIf="footerInfo && footerInfo.length > 0" class="content-container-fat main-container pb-0">
        <h3 *ngIf="isCity" class="menu footer-title">All countries with {{ info.name }} manufacturers</h3>
        <h3 *ngIf="!isCity" class="menu footer-title">All cities with {{ info.name }} manufacturers in {{ info.country.name }}</h3>
        <ul class="row list-unstyled">
          <li *ngFor="let result of footerInfo" class="col-md-4 footer-li">
            <a [routerLink]="(info.city ? '/manufacturers/' + info.uid + '/' + result.uid : '/manufacturers/' + info.uid + '/' + info.country.uid + '/' + result.uid)">- {{ info.name }} Manufacturers in {{ result.name }}</a>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #loadingState>
  <div class="container">
    <div class="content-container-fat main-container">
      <img class="img-fluid" src="/assets/img/indexpagesplaceholder.svg" alt="">
    </div>
  </div>
</ng-template>
